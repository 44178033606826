import React from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"

import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

const Bold = ({ children }) => <p className="font-bold">{children}</p>;

const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
};

// MORE STYLING  ------- https://github.com/contentful/rich-text/tree/master/packages/rich-text-react-renderer

const History = ({ data: { allContentfulHistory: { edges }} }) => {
  if (!edges.length) return null;

  const { node: history } = edges[0];

  return (
    <Layout isExternal>
      <SEO title="History" />

      <div className="pt-24 pb-48 px-6 w-full md:w-10/12 lg:w-8/12 xl:w-8/12 mx-auto">
        <h1 className="mb-4 text-5xl font-bold">{history.title}</h1>
        {documentToReactComponents(history.content.json, options)}
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
      allContentfulHistory {
          edges {
              node {
                  id
                  title
                  content {
                      json
                  }
              }
          }
      }
  }
`;

export default History;
